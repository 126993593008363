import {Directive} from '@angular/core';
import {NgControl} from '@angular/forms';

@Directive({
  standalone: true,
  selector: '[autotrim]'
})
export class AutotrimDirective {
  constructor(ngControl: NgControl) {
    const valueAccessor = ngControl.valueAccessor!;
    const {registerOnChange} = valueAccessor;
    valueAccessor.registerOnChange = (fn: any) =>
      registerOnChange.call(valueAccessor, (value: any) => {
        const trimmedValue = typeof value === 'string' ? value.trim() : value;
        return fn(trimmedValue);
      });
  }
}


<mat-card>
  <mat-card-header>
    <h1 mat-card-title>Create Your Free Account!</h1>
  </mat-card-header>

  <mat-card-content>
    <div>Ready to get started? Choose one of the options below to register your new account and explore all our
      features</div>
    <div class="sso">
      <asl-google-signin-button locale="en" size='large' type='standard'></asl-google-signin-button>
      <button (click)="signInWith('MICROSOFT')" class="microsoft" mat-stroked-button>
        <svg height="21" viewBox="0 0 21 21" width="21" xmlns="http://www.w3.org/2000/svg">
          <rect fill="#f25022" height="9" width="9" x="1" y="1"/>
          <rect fill="#00a4ef" height="9" width="9" x="1" y="11"/>
          <rect fill="#7fba00" height="9" width="9" x="11" y="1"/>
          <rect fill="#ffb900" height="9" width="9" x="11" y="11"/>
        </svg>
        Sign in with Microsoft
      </button>
    </div>

    <div *ngIf="!showRegister" style="margin-top:30px">
      Alternatively, you can <a (click)="showRegister=true">register</a> a new account using your Email and a password
    </div>

    <div *ngIf="showRegister" style="margin-top:30px">
      <h4>Register using your email and password login</h4>

      <form (keydown.enter)="$event.preventDefault()" [formGroup]="registerForm">
        <mat-form-field>
          <mat-label>Email</mat-label>
          <input autotrim email formControlName="email" lowerCase matInput>
          <mat-error>Required field</mat-error>
        </mat-form-field>

        <form-actions>
          <button (click)="registerRequest()" [disabled]="registerForm.invalid || registerForm.pristine" color="primary"
                  mat-raised-button>
            <mat-icon>exit_to_app</mat-icon>
            send registration link
          </button>
        </form-actions>
      </form>
    </div>

  </mat-card-content>
</mat-card>

<mat-card>
  <mat-card-header>
    <h1 mat-card-title>Login</h1>
  </mat-card-header>

  <mat-card-content>
    <div class="sso">
      <asl-google-signin-button locale="en" size='large' type='standard'></asl-google-signin-button>
      <button (click)="signInWith('MICROSOFT')" class="microsoft" mat-stroked-button>
        <svg height="21" viewBox="0 0 21 21" width="21" xmlns="http://www.w3.org/2000/svg">
          <rect fill="#f25022" height="9" width="9" x="1" y="1"/>
          <rect fill="#00a4ef" height="9" width="9" x="1" y="11"/>
          <rect fill="#7fba00" height="9" width="9" x="11" y="1"/>
          <rect fill="#ffb900" height="9" width="9" x="11" y="11"/>
        </svg>
        Sign in with Microsoft
      </button>
    </div>
    <form (keydown.enter)="$event.preventDefault()" [formGroup]="loginForm">
      <h4 style="margin:0;">or use your Email/Password:</h4>
      <mat-form-field>
        <mat-label>Email</mat-label>
        <input autotrim formControlName="email" lowerCase matInput placeholder="Login">
        <mat-error>Required field</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Password</mat-label>
        <input formControlName="password" matInput type="password">
        <mat-error>Required field</mat-error>
      </mat-form-field>

      <form-actions>
        <button [queryParams]="{email:loginForm.controls.email.value}" [routerLink]="['/reset']" mat-stroked-button>
          reset Password
        </button>
        <button (click)="login()" [disabled]="loginForm.invalid" color="primary" mat-raised-button>
          <mat-icon>exit_to_app</mat-icon>
          Login
        </button>
      </form-actions>
    </form>
  </mat-card-content>
</mat-card>

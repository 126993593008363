<mat-card>
  <mat-card-header>
    <h1 mat-card-title>Reset Password</h1>
  </mat-card-header>

  <mat-card-content>
    <form (keydown.enter)="$event.preventDefault()" [formGroup]="form">
      <mat-form-field>
        <mat-label>Email</mat-label>
        <input autotrim email formControlName="email" lowerCase matInput>
        <mat-error>Required field</mat-error>
      </mat-form-field>

      <form-actions>
        <button (click)="resetRequest()" [disabled]="form.invalid || form.pristine" color="primary" mat-raised-button>
          <mat-icon>exit_to_app</mat-icon>
          request Reset
        </button>
      </form-actions>
    </form>
  </mat-card-content>

  <mat-card-actions>
    <a [routerLink]="['/login']">
      back to Login-Page
    </a>
  </mat-card-actions>
</mat-card>


import {Component, inject, OnInit} from '@angular/core';
import {AppAuthService} from './app-auth.service';
import {Router, RouterModule} from '@angular/router';
import {FormBuilder, ReactiveFormsModule, Validators} from '@angular/forms';
import {filter, mergeMap} from 'rxjs/operators';
import {GoogleSigninButtonModule, SocialAuthService, SocialLoginModule} from '@abacritt/angularx-social-login';
import {MatCardModule} from '@angular/material/card';
import {CommonModule} from '@angular/common';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {LowercaseDirective} from '../directives/lowercase.directive';
import {MatIconModule} from '@angular/material/icon';
import {AutotrimDirective} from '../directives/autotrim.directive';
import {FormActionsComponent} from '../form-actions.component';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-login',
  standalone: true,
  templateUrl: 'login.component.html',
  styleUrls: ['login-reset-register.component.scss'],
  imports: [
    CommonModule, ReactiveFormsModule, MatCardModule, MatInputModule, MatIconModule, MatButtonModule, MatDividerModule,
    LowercaseDirective, SocialLoginModule, GoogleSigninButtonModule, RouterModule, AutotrimDirective,
    FormActionsComponent,
  ],
})
export class LoginComponent implements OnInit {
  showRegister: boolean = false;
  loginForm = new FormBuilder().nonNullable.group({
    email: ['', Validators.required], password: ['', Validators.required],
  });
  registerForm = new FormBuilder().nonNullable.group({
    email: ['', Validators.required],
  });
  private router = inject(Router);
  private toastr = inject(ToastrService);
  private authService = inject(AppAuthService);
  private socialAuthService = inject(SocialAuthService);

  async signInWith(id: string) {
    await this.socialAuthService.signIn(id);
  }

  async login() {
    const {email, password} = this.loginForm.value;
    this.loginForm.markAsPristine();
    await this.authService.login(email!, password!);
    await this.navigateHome();
  }

  ngOnInit(): void {
    this.socialAuthService.authState
      .pipe(
        filter(user => !!user),
        mergeMap(({idToken, provider, authToken}) =>
          this.authService.loginByToken(provider, authToken || idToken)))
      .subscribe(() => this.navigateHome());
  }

  async registerRequest() {
    const {email} = this.registerForm.value;
    await this.authService.registerPasswordRequest(email!);
    this.registerForm.markAsPristine();
    this.toastr.success('Request Sent', 'please check your emails');
  }

  private async navigateHome() {
    const returnUrl = localStorage.getItem('returnUrl') || '/';
    localStorage.removeItem('returnUrl');
    console.log(`navigate home/login ${returnUrl}`);
    await this.router.navigate([returnUrl]);
  }
}

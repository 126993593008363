import {Route} from '@angular/router';
import {LoginComponent} from './login.component';
import {ResetComponent} from './reset.component';
import {RequestResetComponent} from './request-reset.component';
import {RegisterComponent} from './register.component';

export {AppAuthService} from './app-auth.service';

export default [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'reset',
    component: RequestResetComponent
  },
  {
    path: 'reset/:token',
    component: ResetComponent
  },
  {
    path: 'register/:token',
    component: RegisterComponent
  },
] as Route[]

import {AfterViewInit, Component, inject, OnInit} from '@angular/core';
import {AppAuthService} from './app-auth.service';
import {ActivatedRoute, RouterModule} from '@angular/router';
import {FormBuilder, ReactiveFormsModule, Validators} from '@angular/forms';
import {GoogleSigninButtonModule, SocialLoginModule} from '@abacritt/angularx-social-login';
import {MatCardModule} from '@angular/material/card';
import {CommonModule} from '@angular/common';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {LowercaseDirective} from '../directives/lowercase.directive';
import {MatIconModule} from '@angular/material/icon';
import {AutotrimDirective} from '../directives/autotrim.directive';
import {ToastrService} from 'ngx-toastr';
import {FormActionsComponent} from '../form-actions.component';

@Component({
  selector: 'app-request-reset',
  standalone: true,
  templateUrl: 'request-reset.component.html',
  styleUrls: ['login-reset-register.component.scss'],
  imports: [
    CommonModule, ReactiveFormsModule, MatCardModule, MatInputModule, MatIconModule, MatButtonModule, MatDividerModule,
    LowercaseDirective, SocialLoginModule, GoogleSigninButtonModule, RouterModule, AutotrimDirective,
    FormActionsComponent,
  ]
})
export class RequestResetComponent implements OnInit {
  form = new FormBuilder().nonNullable.group({
    email: ['', Validators.required]
  });
  private route = inject(ActivatedRoute);
  private toastr = inject(ToastrService);
  private authService = inject(AppAuthService);

  async resetRequest() {
    await this.authService.resetPasswordRequest(this.form.controls.email.value!!);
    this.form.markAsPristine();
    this.toastr.success('Request Sent', 'please check your emails');
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(({email}) => {
      this.form.patchValue({email});
    });
  }
}

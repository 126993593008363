import {Component, OnInit} from '@angular/core';
import {AppAuthService} from './app-auth.service';
import {ActivatedRoute, Router, RouterModule} from '@angular/router';
import {FormBuilder, NgControl, ReactiveFormsModule, Validators} from '@angular/forms';
import {GoogleSigninButtonModule, SocialLoginModule} from '@abacritt/angularx-social-login';
import {MatCardModule} from '@angular/material/card';
import {CommonModule} from '@angular/common';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {LowercaseDirective} from '../directives/lowercase.directive';
import {MatIconModule} from '@angular/material/icon';
import {AutotrimDirective} from '../directives/autotrim.directive';
import {ToastrService} from 'ngx-toastr';
import {FormActionsComponent} from '../form-actions.component';

@Component({
  selector: 'app-reset',
  standalone: true,
  templateUrl: 'reset.component.html',
  styleUrls: ['login-reset-register.component.scss'],
  imports: [
    CommonModule, ReactiveFormsModule, MatCardModule, MatInputModule, MatIconModule, MatButtonModule, MatDividerModule,
    LowercaseDirective, SocialLoginModule, GoogleSigninButtonModule, RouterModule, AutotrimDirective,
    FormActionsComponent
  ]
})
export class ResetComponent implements OnInit {
  token!: string;
  form = new FormBuilder().nonNullable.group({
    password: [undefined as string | undefined, Validators.minLength(8)],
    rePassword: [undefined as string | undefined, (control: NgControl) =>
      control.value === this.form?.controls?.password?.value ? null : {passwordMismatch: true}
    ]
  });

  constructor(private router: Router, private authService: AppAuthService,
              private route: ActivatedRoute, private toastr: ToastrService) {
  }


  async updatePassword() {
    await this.authService.resetPasswordCompete(this.token, this.form.controls.password.value!!);
    this.toastr.success('Password Updated');
    await this.router.navigate(['/']);
  }

  ngOnInit(): void {
    this.route.params.subscribe(({token}) => this.token = token);
    this.form.controls.password.valueChanges.subscribe(() =>
      this.form.patchValue({rePassword: this.form.value.rePassword || ''}, {emitEvent: false}));
    this.form.controls.rePassword.valueChanges.subscribe(() =>
      this.form.patchValue({password: this.form.value.password || ''}, {emitEvent: false}));
  }
}

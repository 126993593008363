import {firstValueFrom, ObservableInput, of, ReplaySubject, Subject} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {HttpClient, HttpHeaders, HttpXhrBackend} from '@angular/common/http';
import {JwtHelperService} from '@auth0/angular-jwt';
import {Injectable} from '@angular/core';
import {MatomoTracker} from 'ngx-matomo-client';

const jwt = new JwtHelperService();

export const LS_AUTH_TOKEN = 'supplycanvas-auth-token';

export class AuthUser {
  email: string;
  token: string;
  roles: string[];
  incomplete: boolean;
  impersonated?: string;

  constructor(res: AuthUser) {
    this.token = res.token;
    this.incomplete = res.incomplete;
    try {
      const decodeToken = jwt.decodeToken(this.token);
      this.email = decodeToken.sub;
      this.roles = decodeToken.a || [];
      this.impersonated = decodeToken.i;
    } catch (e) {
      console.error('failed decoding token:', e);
      throw e;
    }
  }
}

@Injectable({providedIn: 'root'})
export class AppAuthService {
  user?: AuthUser;
  user$: Subject<AuthUser | undefined> = new ReplaySubject<AuthUser | undefined>(1);
  isLoggedIn$ = this.user$.pipe(map(user => !!user));
  private http: HttpClient;

  constructor(backend: HttpXhrBackend, matomoTracker: MatomoTracker) {
    this.http = new HttpClient(backend);
    this.user$.subscribe(user => {
      if (user) {
        localStorage.setItem(LS_AUTH_TOKEN, user.token);
      } else {
        this.clearToken();
      }
      this.user = user;
      matomoTracker.setUserId(user?.email || 'anonymous');
    });

    const token = localStorage.getItem(LS_AUTH_TOKEN);
    if (token) {
      this.check().then();
    } else {
      this.user$.next(undefined);
    }
  }

  get isImpersonated(): boolean {
    return !!this.user?.impersonated;
  }

  get isAdmin(): boolean {
    return this.user && this.user.roles?.indexOf('ADMIN') > -1 || false;
  }

  get isLoggedIn() {
    return !!this.user;
  }

  async check(switchBack: boolean = false) {
    console.log('check login');
    return firstValueFrom(this.http.get<AuthUser>(
      'api/auth/refresh' + (switchBack ? '?switchBack' : ''),
      {headers: this.authHeaders()})
      .pipe(
        map(res => new AuthUser(res)),
        tap(res => this.user$.next(res)),
        catchError(err => {
          if (err.status !== 401 && err.status !== 403) {
            throw err;
          }
          this.user$.next(undefined);
          return of(null);
        })
        // shareReplay(1, 60000)
      ));
  }

  async login(email: string, password: string) {
    this.clearToken();
    return firstValueFrom(this.http.post<AuthUser>('api/auth/login', {email, password})
      .pipe(
        map(res => new AuthUser(res)),
        tap(res => this.user$.next(res)),
        catchError((err): ObservableInput<any> => {
          this.user$.next(undefined);
          throw err;
        })
      ));
  }

  async registerPasswordRequest(email: string): Promise<any> {
    this.clearToken();
    this.user$.next(undefined);
    return firstValueFrom(this.http.post<any>('api/auth/reset', {email, register: true}));
  }

  async resetPasswordRequest(email: string): Promise<any> {
    this.clearToken();
    this.user$.next(undefined);
    return firstValueFrom(this.http.post<any>('api/auth/reset', {email}));
  }

  async resetPasswordCompete(token: string, password: string): Promise<AuthUser> {
    this.clearToken();
    this.user$.next(undefined);
    return firstValueFrom(this.http.post<AuthUser>('api/auth/reset', {token, password})
      .pipe(
        map(res => new AuthUser(res)),
        tap(res => this.user$.next(res)),
        catchError((err): ObservableInput<any> => {
          this.user$.next(undefined);
          throw err;
        })
      ));
  }

  async loginByToken(provider: string, token: string): Promise<AuthUser> {
    this.clearToken();
    return firstValueFrom(this.http.post<AuthUser>(`api/auth/login/${provider}`, {token})
      .pipe(
        map(res => new AuthUser(res)),
        tap(res => this.user$.next(res)),
        catchError((err): ObservableInput<any> => {
          this.user$.next(undefined);
          throw err;
        })
      ));
  }

  logout(): void {
    this.clearToken();
  }

  async switchBack() {
    return await this.check(this.isImpersonated);
  }

  private clearToken() {
    localStorage.removeItem(LS_AUTH_TOKEN);
  }

  private authHeaders() {
    const token = localStorage.getItem(LS_AUTH_TOKEN);
    return new HttpHeaders(token ? {Authorization: 'Bearer ' + token} : {});
  }
}

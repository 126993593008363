import {Component, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'form-actions',
  standalone: true,
  template: `
    <div class="form-actions">
      <div class="spacer"></div>
      <ng-content></ng-content>
    </div>
  `,
  styles: [`
    .form-actions {
      display: flex;
      gap: 1em;
      align-items: center;

      a, button {
        text-transform: uppercase;
      }

      .spacer {
        flex-grow: 1;
      }
    }
  `],
  encapsulation: ViewEncapsulation.None
})
export class FormActionsComponent {
}
